export default {
    data() {
        return {
            windowinnerHeight: 0,
        };
    },
    methods: {
        // 侦听页面高度变化回调函数
        eventListening() {
            this.windowinnerHeight = window.innerHeight;
        },
    },
    computed: {
        tableHeight() {
            return (deductionHeight = 0) => {
                return this.windowinnerHeight - deductionHeight + 'px';
            };
        },
    },
    created() {
        this.windowinnerHeight = window.innerHeight;
        window.addEventListener('resize', this.eventListening);
    },
    beforeDestroy() {
        // 销毁前移除页面高度侦听,防止内存泄漏
        window.removeEventListener('resize', this.eventListening);
    },
};
