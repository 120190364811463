import request from './request.js';
import public_request from './public_request';
//获取实时新线索列表
export function synchNewClue(data) {
    return request({
        url: '/clue-pool/my/clues-real-time-new',
        method: 'get',
        params: public_request(data),
    });
}
// 新线索列表
export function newClue(data) {
    return request({
        url: '/clue-pool/my/clues-new',
        method: 'get',
        params: public_request(data),
    });
}
//获取未确认线索列表
export function clues_unconfirmed(data) {
    return request({
        url: '/clue-pool/my/clues-unconfirmed',
        method: 'get',
        params: public_request(data),
    });
}
//获取抓取线索列表
export function clues_caught(data) {
    return request({
        url: '/clue-pool/my/clues-caught',
        method: 'get',
        params: public_request(data),
    });
}
//获取转给我的线索列表
export function clues_transferred_me(data) {
    return request({
        url: '/clue-pool/my/clues-transferred-me',
        method: 'get',
        params: public_request(data),
    });
}
//获取线索记录列表
export function clue_logs(data) {
    return request({
        url: '/clue-pool/clue-log/logs',
        method: 'get',
        params: public_request(data),
    });
}
//获取单个线索信息
export function clue({ clueId }) {
    return request({
        url: '/clue-pool/clue',
        method: 'get',
        params: public_request({
            clueId,
        }),
    });
}
//保存线索跟进记录
export function clue_followed_log(data) {
    return request({
        url: '/clue-pool/clue-followed-log',
        method: 'POST',
        data: public_request(data),
    });
}
// 转移线索
export function transfer_clue({ clueId, staffId }) {
    return request({
        url: '/clue-pool/clue-owner',
        method: 'put',
        data: public_request({
            clueId,
            staffId,
        }),
    });
}
// 获取上一条下一条
export function clueAround(data) {
    return request({
        url: '/clue-pool/clue-around',
        method: 'get',
        params: public_request(data),
    });
}
// 创建我的线索轮呼列表
export function createMyClueBatchFollow(list) {
    return request({
        url: '/clue-pool/my/batch-follow-list',
        method: 'POST',
        data: public_request({
            list,
        }),
    });
}
// 获取我的线索轮呼列表
export function getMyClueBatchFollow() {
    return request({
        url: '/clue-pool/my/batch-follow-list',
        method: 'GET',
        params: public_request(),
    });
}
// 获取实时新线索轮呼列表
export function getRealTimeClue() {
    return request({
        url: '/clue-pool/my/real-time-batch-follow-list',
        method: 'GET',
        params: public_request(),
    });
}
// 获取公共池线索随机轮呼列表
export function getPublicClueRandomList(size) {
    return request({
        url: '/clue-pool/public/batch-follow-random-list',
        method: 'GET',
        params: public_request({ size }),
    });
}
// 获取我的轮呼线索信息
export function getMyClueInfo(clueId) {
    return request({
        url: '/clue-pool/my/batch-follow-clue',
        method: 'GET',
        params: public_request({
            clueId,
        }),
    });
}
// 获取公共池轮呼线索信息
export function getPublicClueInfo(clueId) {
    return request({
        url: '/clue-pool/public/batch-follow-clue',
        method: 'GET',
        params: public_request({
            clueId,
        }),
    });
}
// 延长线索过期时间
export function addClueExpire(clueId) {
    return request({
        url: '/common/clue-expire',
        method: 'PUT',
        data: public_request({
            clueId,
        }),
    });
}
