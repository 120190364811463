//时间戳转为可读时间
function getYMDHMS(timestamp) {
    let time = new Date(timestamp * 1000);
    let year = time.getFullYear();
    const month = (time.getMonth() + 1).toString().padStart(2, '0');
    const date = time
        .getDate()
        .toString()
        .padStart(2, '0');
    const hours = time
        .getHours()
        .toString()
        .padStart(2, '0');
    const minute = time
        .getMinutes()
        .toString()
        .padStart(2, '0');
    const second = time
        .getSeconds()
        .toString()
        .padStart(2, '0');
    return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second;
}
//时间戳转为可读时间
function getYMDHMSNh(timestamp) {
    let time = new Date(timestamp * 1000);
    let year = time.getFullYear();
    const month = (time.getMonth() + 1).toString().padStart(2, '0');
    const date = time
        .getDate()
        .toString()
        .padStart(2, '0');
    return year + '-' + month + '-' + date;
}

// 时间戳转为可读时间,这是百度找的方法
function formatDate(value) {
    if (value == null) {
        return '';
    } else {
        let date = new Date(value);
        let y = date.getFullYear(); // 年
        let MM = date.getMonth() + 1; // 月
        MM = MM < 10 ? '0' + MM : MM;
        let d = date.getDate(); // 日
        d = d < 10 ? '0' + d : d;
        let h = date.getHours(); // 时
        h = h < 10 ? '0' + h : h;
        let m = date.getMinutes(); // 分
        m = m < 10 ? '0' + m : m;
        let s = date.getSeconds(); // 秒
        s = s < 10 ? '0' + s : s;
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    }
}
export { getYMDHMS, getYMDHMSNh, formatDate };
