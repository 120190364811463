<template>
    <div>
        <el-dialog
            custom-class="tipsDialog"
            :title="title"
            width="400px"
            :visible="isShow"
            :modal="false"
            :close-on-click-modal="false"
            @close="close"
        >
            <el-form ref="formData" label-width="120px" :model="formData" :rules="formRules">
                <!-- 超时时间 -->
                <el-form-item
                    v-if="optional.includes('超时时间')"
                    label="超时时间设置："
                    prop="expireTime"
                    class="expire-time"
                    size="mini"
                >
                    <el-date-picker
                        v-model="formData.expireTime"
                        size="mini"
                        type="date"
                        class
                        placeholder="请选择日期时间"
                        value-format="timestamp"
                        :picker-options="pickersOptions"
                    />
                </el-form-item>

                <!-- 导入位置 -->
                <div v-if="optional.includes('导入位置')">
                    <el-form-item label="导入位置：" prop="target" size="mini">
                        <el-select
                            v-model="formData.target"
                            size="mini"
                            placeholder="请选择位置"
                            clearable
                        >
                            <el-option
                                v-for="item in targetArr"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>

                    <el-form-item v-if="departmentId" size="mini" prop="departmentId">
                        <el-cascader
                            ref="cascaderHandle"
                            v-model="formData.departmentId"
                            size="mini"
                            :options="departmentsArr"
                            :props="props"
                            placeholder="选择部门"
                            clearable
                            @change="selDepartVal"
                        />
                    </el-form-item>

                    <el-form-item v-if="staffIds" size="mini" prop="staffId">
                        <el-select
                            v-model="formData.staffId"
                            size="mini"
                            placeholder="请选择员工"
                            clearable
                        >
                            <el-option
                                v-for="item in staffOption"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>

                    <el-form-item v-if="!departmentId" size="mini" prop="departmentId">
                        <el-select
                            v-model="formData.departmentId"
                            clearable
                            size="mini"
                            placeholder="请选择公海"
                        >
                            <el-option
                                v-for="item in departmentsArr1"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" plain @click="confirm">确定</el-button>
                <el-button type="primary" plain @click="$emit('update:isShow',false)">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { departments, staff, companySea } from '@/api/public_content.js';
export default {
    name: 'RestoreDialog',
    props: {
        isShow: {
            type: Boolean,
            default: () => false,
        },
        title: {
            type: String,
            default: () => '',
        },
        optional: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            formData: {
                expireTime: '', // 超时截止时间戳，秒
                target: '', // 导入位置，1-员工个人，2-本部门员工，3-本部门及下级部门员工，4-公共池
                departmentId: '', // 部门ID，导入位置为2、3、4时必填
                staffId: '', // 员工ID，导入位置为1时必填
            },
            formRules: {
                expireTime: [
                    { required: true, message: '请选择时间', trigger: 'blur' },
                ],
                target: [
                    { required: true, message: '请选择位置', trigger: 'blur' },
                ],
                departmentId: [
                    { required: true, message: '请选择', trigger: 'blur' },
                ],
                staffId: [
                    { required: true, message: '请选择', trigger: 'blur' },
                ],
            },
            departmentsArr: [],
            departVal: '',
            staffOption: [],
            staffId: '',
            props: {
                expandTrigger: 'hover',
                checkStrictly: true,
                label: 'name',
                value: 'id',
            },
            targetArr: [
                { id: '1', name: '员工个人' },
                { id: '2', name: '本部门员工' },
                { id: '3', name: '本部门及下级部门员工' },
                { id: '4', name: '公共池' },
            ],
            pickersOptions: {
                disabledDate: (time) => {
                    return time.getTime() < Date.now() - 3600 * 1000 * 24;
                },
                selectableRange: '00:00:00-23:59:59',
            },
            departmentsArr1: [],
        };
    },
    methods: {
        getDepartment() {
            departments().then((res) => {
                if (res.code == 0) {
                    this.departmentsArr = this.common.getTreeData(
                        res.data.list
                    );
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        selDepartVal(val) {
            this.staffOption = [];
            this.formData.staffId = '';
            if (val[val.length - 1] === undefined) return;
            this.$refs.cascaderHandle.dropDownVisible = false;
            staff({ departmentId: val[val.length - 1] }).then((res) => {
                if (res.code == 0) {
                    this.staffOption = res.data.list;
                    this.formData.staffId = '';
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        confirm() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    this.$emit('update:isShow', false);
                    const obj = { ...this.formData };
                    obj.expireTime = this.formData.expireTime / 1000;
                    obj.departmentId = Array.isArray(this.formData.departmentId)
                        ? this.formData.departmentId[
                              this.formData.departmentId.length - 1
                          ]
                        : this.formData.departmentId;
                    this.$emit('confirm', obj);
                } else {
                    return false;
                }
            });
        },
        close() {
            this.$emit('update:isShow', false);
            this.$emit('close');
        },
        async companySea() {
            const { data } = await companySea();
            this.departmentsArr1 = data.list;
        },
    },
    computed: {
        departmentId() {
            if (this.formData.target === '4') return false;
            return true;
        },
        staffIds() {
            if (this.formData.target === '1') return true;
            return false;
        },
    },
    watch: {
        isShow(newData) {
            if (newData) {
                this.getDepartment();
                for (const key in this.formData) {
                    this.formData[key] = '';
                }
            }
        },
        'formData.target'(newVal) {
            this.formData.staffId = '';
            this.formData.departmentId = '';
        },
    },
    mounted() {
        this.restoreDialog = true;
        this.getDepartment();
        this.companySea();
    },
};
</script>

<style lang="less" scoped>
.el-form {
    padding: 0 25px;
    overflow: hidden;
}
.el-form-item {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    height: 48px;
}
/deep/ .el-input__suffix {
    height: 28px;
}
.expire-time {
    /deep/ .el-input--mini {
        padding: 0 18px 0 9px;
    }
    /deep/ .el-input__icon {
        transform: translate(10px, -9px);
    }
    /deep/ .el-input__suffix {
        top: 9px;
        right: 30px;
    }
}
/deep/ .el-form-item__error {
    top: 32px;
    left: 17px;
}
</style>