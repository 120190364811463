<template>
    <div id="playMusic">
        <audio
            ref="audio"
            :src="src"
            @pause="onPause"
            @play="onplay"
            @timeupdate="onTimeupdate"
            @loadedmetadata="onLoadedmetadata"
        />
        <!-- <el-button type='text' @click='startPlayOrPause'>{{audio.playing | transPlayPause}}</el-button> -->
        <img :src="audio.playing?stop:start" class="change-style" @click="startPlayOrPause" />
        <!-- <div :class="audio.playing?'stop':'start'" @click="startPlayOrPause"></div> -->
        <!-- 进度条 -->
        <el-slider
            v-model="sliderTime"
            :format-tooltip="formatProcessToolTip"
            class="slider"
            @change="changeCurrentTime"
        />
        <div class="timeStep" :style="{width:width,fontSize:fontSize}">
            <div>{{ audio.currentTime | formartSecond }} /</div>
            <div>{{ audio.maxTime | formartSecond }}</div>
        </div>
    </div>
</template>

<script>
function realFormatSecond(second) {
    let secondType = typeof second;

    if (secondType === 'number' || secondType === 'string') {
        second = parseInt(second);

        let hours = Math.floor(second / 3600);
        second = second - hours * 3600;
        let mimute = Math.floor(second / 60);
        second = second - mimute * 60;

        return (
            hours +
            ':' +
            ('0' + mimute).slice(-2) +
            ':' +
            ('0' + second).slice(-2)
        );
    } else {
        return '0:00:00';
    }
}
export default {
    data() {
        return {
            sliderTime: 0,
            audio: {
                maxTime: 0,
                /* 音频最大播放时长 */
                currentTime: 0,
                /* 当前播放时长 */
                playing: false /* 音频当前处于播放/暂停状态 */,
            },
            stop: require('../assets/audio/stop.png'),
            start: require('../assets/audio/start.png'),
        };
    },
    props: {
        src: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            default: '',
        },
        height: {
            type: String,
            default: '',
        },
        fontSize: {
            type: String,
            default: '',
        },
    },
    methods: {
        /* 进度条格式化toolTip */
        formatProcessToolTip(index = 0) {
            index = parseInt((this.audio.maxTime / 100) * index);
            return '时长：' + realFormatSecond(index);
        },
        /* 拖动进度条，改变当前时间 index是进度条改变时的回调函数的参数 值为0~100之间，需要换算成实际时间 */
        changeCurrentTime(index) {
            this.$refs.audio.currentTime = parseInt(
                (index / 100) * this.audio.maxTime
            );
        },
        /* 音频加载完成后的回调函数 */
        onLoadedmetadata(res) {
            this.audio.maxTime = parseInt(res.target.duration);
        },
        /* 每秒触发一次 用来更新当前播放时间 */
        onTimeupdate(res) {
            this.audio.currentTime = res.target.currentTime;
            /* 当音频播放时 进度条也要随之改变 */
            this.sliderTime = parseInt(
                (this.audio.currentTime / this.audio.maxTime) * 100
            );
        },
        /* 控制音频播放、暂停 */
        startPlayOrPause() {
            this.audio.playing ? this.pause() : this.play();
        },
        /* 播放音频 */
        play() {
            this.$refs.audio.play();
        },
        /* 暂停音频 */
        pause() {
            this.$refs.audio.pause();
        },
        /* 当音频播放 */
        onplay() {
            this.audio.playing = true;
        },
        /* 当音频暂停 */
        onPause() {
            this.audio.playing = false;
        },
    },
    filters: {
        /* 使用vue过滤器动态改变按钮的显示 */
        transPlayPause(value) {
            return value ? '暂停' : '播放';
        },
        /* 整数转换时分秒 */
        formartSecond(second = 0) {
            return realFormatSecond(second);
        },
    },
};
</script>

<style lang="less" scoped="scoped">
#playMusic {
    display: flex;
    align-items: center;

    .el-button {
        margin-right: 15px;
    }

    .slider {
        width: 110px;
        margin-right: 15px;
    }

    // > span:nth-of-type(2) {
    //   margin-left: 5px;
    // }
    .timeStep {
        display: flex;
        flex-direction: row;
    }

    .change-style {
        width: 30px;
        height: 30px;
        margin-right: 15px;
    }
}

.start {
    background-image: url('../assets/audio/start.png');
}

.stop {
    background-image: url('../assets/audio/stop.png');
    // background-repeat: no-repeat;
}

.playcontroll {
}
</style>
