import request from './request.js';
import public_request from './public_request';
//获取超时新线索列表
export function clues_new({ qualityType, fundNeedsScopeType, pageNo, pageSize, startTime, endTime }) {
    return request({
        url: '/clue-pool/public/clues-new',
        method: 'get',
        params: public_request({
            qualityType,
            fundNeedsScopeType,
            pageNo,
            pageSize,
            startTime,
            endTime,
        }),
    });
}
//获取超时未确认列表
export function clues_unconfirmed({ qualityType, fundNeedsScopeType, unconfirmedTag, pageNo, pageSize, startTime, endTime }) {
    return request({
        url: '/clue-pool/public/clues-unconfirmed',
        method: 'get',
        params: public_request({
            qualityType,
            fundNeedsScopeType,
            unconfirmedTag,
            pageNo,
            pageSize,
            startTime,
            endTime,
        }),
    });
}
//获取无效线索列表
export function clues_invalid({ qualityType, fundNeedsScopeType, invalidTag, pageNo, pageSize, startTime, endTime }) {
    return request({
        url: '/clue-pool/public/clues-invalid',
        method: 'get',
        params: public_request({
            qualityType,
            fundNeedsScopeType,
            invalidTag,
            pageNo,
            pageSize,
            startTime,
            endTime,
        }),
    });
}
//抓取公共池线索
export function clue_owner({ clueId }) {
    return request({
        url: '/clue-pool/public/clue-owner',
        method: 'PUT',
        data: public_request({
            clueId,
        }),
    });
}
// 获得公共池线索
export function clue_Public(data) {
    return request({
        url: '/clue-pool/public/clues',
        method: 'get',
        params: public_request(data),
    });
}
