import { render, staticRenderFns } from "./callAudio.vue?vue&type=template&id=ca787420&scoped=true&"
import script from "./callAudio.vue?vue&type=script&lang=js&"
export * from "./callAudio.vue?vue&type=script&lang=js&"
import style0 from "./callAudio.vue?vue&type=style&index=0&id=ca787420&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca787420",
  null
  
)

export default component.exports