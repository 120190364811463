import request from './request.js';
import public_request from './public_request';
//重分配公海客户
export function clients_owner({ clientIds, staffId }) {
    return request({
        url: '/client-management/public/clients-owner',
        method: 'put',
        data: public_request({
            clientIds,
            staffId,
        }),
    });
}
//抓取公海客户
export function client_owner(data) {
    return request({
        url: '/client-management/public/client-owner',
        method: 'put',
        data: public_request(data),
    });
}
//获取公海客户列表
export function clients(data) {
    return request({
        url: '/client-management/public/clients',
        method: 'get',
        params: public_request(data),
    });
}
